<template>
  <v-row justify="end">
    <v-col class="pa-0 text-center" v-if="!gs.isMobile()" cols="12" md="7">
      <img
        alt="banker Register"
        src="@/assets/banker-register-bg.svg"
        style="
          padding: 48px;
          object-fit: contain;
          width: inherit;
          max-height: 950px;
        "
      />
    </v-col>
    <v-col
      class="pa-0"
      cols="12"
      md="5"
      style="display: flex; justify-content: start; align-items: center"
    >
      <div :class="`${gs.isMobile() ? 'px-4' : 'pr-12'}`">
        <v-row justify="center" class="my-4" v-if="loading">
          <v-progress-circular indeterminate size="20"></v-progress-circular>
        </v-row>
        <div v-else>
          <v-row
            justify="center"
            align="center"
            :class="`text-${gs.isMobile() ? 'center' : 'left'} ${
              gs.isMobile() && 'py-2'
            }`"
          >
            <v-col cols="12">
              <h1
                style="
                  font-weight: bold;
                  color: var(--white);
                  font-size: 41px;
                  line-height: 42px;
                "
                :class="`px-5 ${gs.isMobile() && 'mt-8'}`"
              >
                {{ $t(`banker_register_1`) }}
              </h1>
            </v-col>
            <v-col cols="12">
              <h5
                class="px-5 my-3 mb-5"
                style="font-size: 18px; line-height: 21px; color: var(--white)"
              >
                {{ $t(`banker_register_2`) }}
              </h5>
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="redirect_to_register"
                class="px-5 dxa-btn-primary-blue register-btn"
                data-test="banker:signup:button"
              >
                {{ $t("register_action") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="mt-2">
              <a
                @click="redirect_to_login"
                class="ml-5"
                style="
                  font-size: 16px;
                  text-decoration: underline;
                  color: #1a7dff;
                  font-weight: 700;
                "
              >
                {{ $t("already_registered") }}
              </a>
            </v-col>
          </v-row>

          <div class="py-6" :class="`${gs.isMobile() ? 'px-4' : ''}`">
            <h2
              class="text-center"
              style="color: var(--white); font-size: 30px; font-weight: bolder"
            >
              {{ $t("alpha_register_1_info_1_main") }}
            </h2>
            <h5
              class="text-center"
              style="font-size: 18px; color: var(--white)"
            >
              {{ $t("alpha_register_1_info_1_desc") }}
            </h5>
            <h2
              class="text-center mt-4"
              style="color: var(--white); font-size: 30px; font-weight: bolder"
            >
              {{ $t("alpha_register_1_info_2_main") }}
            </h2>
            <h5
              class="text-center"
              style="font-size: 18px; color: var(--white)"
            >
              {{ $t("alpha_register_1_info_2_desc") }}
            </h5>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="pa-0" cols="0" md="1"> </v-col>
  </v-row>
</template>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { mapGetters } from "vuex";
import mixpanel from "mixpanel-browser";
import { bankerRegisterWrongUrl } from "@/shared/helpers/bankerRegisterHelper";

export default {
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),
      validating: false,
      alert: null,
      code: null,
      loading: false,
      user: {},
      partnerData: null,
    };
  },
  computed: {
    ...mapGetters({
      configs: "get_partner",
    }),
  },
  async created() {
    let bankerFormData = {
      ...this.$route.query,
    };
    localStorage.setItem("bankerFormData", JSON.stringify(bankerFormData));

    this.loading = true;
    if (this.$route.query.partner) {
      await this.apiService
        .getRequest(`partner/get/${this.$route.query.partner}`)
        .then((result) => {
          this.$store.commit("SET_PARTNER", JSON.parse(result));
          this.user.PartnerId = this.configs.Id;
          this.partnerData = JSON.parse(result);

          // DISPARA O EVENTO NO SUCESSO DA REQUEST QUE MONTA A TELA
          mixpanel.track("BankerLanding_View", {
            partner_name: this.partnerData.Name,
            partner_id: this.partnerData.Id,
          });
        })
        .catch((err) => {
          if (err.status === 400) {
            bankerRegisterWrongUrl();
          }
        });
    } else {
      bankerRegisterWrongUrl();
    }
    this.loading = false;
  },

  methods: {
    redirect_to_register() {
      this.$router.push(
        `/auth/banker-register?partner=${this.$route.query.partner}`
      );
    },
    redirect_to_login() {
      this.$router.push(`/auth`);
    },
  },
};
</script>
<style>
.crisp-client {
  display: none;
}
</style>
<style scoped>
.register-btn {
  width: 320px;
  height: 50px !important;
  font-size: 22px !important;
  box-shadow: 0px 4px 20px #1a7dff !important;
  margin-left: 20px !important;
}
</style>
